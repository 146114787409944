import styled from "styled-components";
import {Col} from "react-bootstrap";
import {Row} from "../../GlobalStyles";
import Card from "../../components/Card";

export const SkillsWrapper = styled.div`

`

export const SkillsRow = styled(Row)`
  margin-top: 2.5rem;
  /*gap: 0.5rem;

  @media (min-width: 1024px) {
    gap: 1.25rem;
  }

  @media (min-width: 768px) {
    gap: 0.75rem;
  }*/

`;

export const SkillItem = styled(Card)`




`;
